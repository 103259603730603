import React from 'react'
import Empcar from './components/Empcar'
import { NavBar } from '../../components'
import { Contact } from '../../containers'

const BusinessConsulting = () => {
  return (
   <div>
          <NavBar/>
          <Empcar/>
          <Contact/>
   </div>
  )
}

export default BusinessConsulting