import "../DigitalMarketing.css";
function DigitalMarketingHeader2(){
    return(<div className="DMH2-main">
     
     <div className="DMH-hr"><div></div></div>
     <div className="DMH2-heading">Technology Expertise</div>
     <div className="DMH2-subtitle">At GetMax Digital Solutions:</div>

    </div>)
}

export default DigitalMarketingHeader2;