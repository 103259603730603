import "../DigitalMarketing.css";
function DigitalMarketingHeader(){
    return(<div className="DMH-main">
     
     <div className="DMH-hr"><div></div></div>
     <div className="DMH-heading">Services</div>
     <div className="DMH-subtitle">We Provide</div>

    </div>)
}

export default DigitalMarketingHeader;