import img_1 from '../../assets/team-img1.png'
import img_2 from '../../assets/team-img2.png'
export const teamMembers = [
    {
      name: 'Yuvaraj',
      position: 'Co-founder/CTO',
      imgSrc: img_2,
      githubUrl: 'https://github.com/',
      linkedinUrl: 'https://www.linkedin.com/',
      gmailUrl: 'https://www.google.com/'
    },
    {
      name: 'Mariyam',
      position: 'Lead HR officer',
      imgSrc: img_1,
      githubUrl: 'https://github.com/',
      linkedinUrl: 'https://www.linkedin.com/',
      gmailUrl: 'https://www.google.com/'
    },
    {
      name: 'Reshma',
      position: 'Business Development Lead',
      imgSrc: img_1,
      githubUrl: 'https://github.com/',
      linkedinUrl: 'https://www.linkedin.com/',
      gmailUrl: 'https://www.google.com/'
    },
    {
      name: 'Tanmaie Usha Shree',
      position: 'Chief Branding Executive',
      imgSrc: img_1,
      githubUrl: 'https://github.com/',
      linkedinUrl: 'https://www.linkedin.com/',
      gmailUrl: 'https://www.google.com/'
    },
    {
      name: 'Tharun Balaji R',
      position: 'Lead App Developer',
      imgSrc: img_1,
      githubUrl: 'https://github.com/',
      linkedinUrl: 'https://www.linkedin.com/',
      gmailUrl: 'https://www.google.com/'
    },
    {
      name: 'Aayisha Noora J',
      position: 'Chief UI/UX designer',
      imgSrc: img_1,
      githubUrl: 'https://github.com/',
      linkedinUrl: 'https://www.linkedin.com/',
      gmailUrl: 'https://www.google.com/'
    },
    {
      name: 'Arun Pranav A T',
      position: 'Automation lead',
      imgSrc: img_1,
      githubUrl: 'https://github.com/',
      linkedinUrl: 'https://www.linkedin.com/',
      gmailUrl: 'https://www.google.com/'
    },
    {
      name: 'Amrize JK',
      position: 'Sales Manager',
      imgSrc: img_1,
      githubUrl: 'https://github.com/',
      linkedinUrl: 'https://www.linkedin.com/',
      gmailUrl: 'https://www.google.com/'
    }
    


  ];

