import img from './Assets/figma.svg'




const CareersData = [
    {
        job : "DEV",
        desc : "hola amigo",
        price : "$70000-90000",
        comp : "Gmsol"
    }, {
        job : "DEV",
        desc : "hola amigo",
        price : "$70000-90000",
        comp : "Gmsol"
    }, {
        job : "DEV",
        desc : "hola amigo",
        price : "$70000-90000",
        comp : "Gmsol"
    }, {
        job : "DEV",
        desc : "hola amigo",
        price : "$70000-90000",
        comp : "Gmsol"
    }, {
        job : "DEV",
        desc : "hola amigo",
        price : "$70000-90000",
        comp : "Gmsol"
    }, {
        job : "adsfj;l",
        desc : "We are seeking a data scientist to join our team.ut neque.f",
        price : "$70000-90000",
        comp : "Gmsolasdsdfasdfadf"
    }, {
        job : "DEV",
        desc : "hola amigo",
        price : "$70000-90000",
        comp : "Gmsol"
    }, {
        job : "DEV",
        desc : "hola amigo",
        price : "$70000-90000",
        comp : "Gmsol"
    }, {
        job : "DEV",
        desc : "hola amigo",
        price : "$70000-90000",
        comp : "Gmsol"
    }, {
        job : "DEV",
        desc : "hola amigo",
        price : "$70000-90000",
        comp : "Gmsol"
    }, {
        job : "DEV",
        desc : "hola amigo",
        price : "$70000-90000",
        comp : "Gmsol"
    }, {
        job : "Design",
        desc : "hola amigo",
        price : "$70000-90000",
        comp : "Gmsol"
    }
]

export default CareersData;